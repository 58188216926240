body,
html,
h1,
h2,
h3,
h4 {
  margin: 0;
  font-family: 'Helvetica Neue', sans-serif ;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  overflow-y: auto;
  height: 100%;
}

.makeStyles-paper-11,
.makeStyles-paper-11 h2,
.MuiTypography-body1,
.MuiPaper-root
.MuiPaper-root h2 {
  color: #000000;
}

body {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-position: center center !important;
  background-attachment: fixed !important;
  background-size: cover !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Helvetica Neue', sans-serif;
}

.cardop {
  background-color: rgba(46, 15, 3, 0.75) !important;
  border-radius: 20px !important;
  border: 1px solid #DAC0AA !important;
}

.MuiToolbar-regular {
  color: #e0e3bd;
}

.MuiButton-containedPrimary,
.MuiButton-containedPrimary:hover,
.MuiListItem-button:hover,
.MuiButtonBase-root:hover {
  color: #fff !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.wallet-button {
  color: #e79c23 !important;
  font-family: 'Poppins', sans-serif !important;
  font-size: 1.1rem !important;
  text-align: left !important;
  justify-content: flex-start !important;
  text-transform: none !important;
}
.wallet-button:hover {
  background-color: black !important;
  color: #e0b47a !important;
}

@font-face {
  font-family: "Cubic";
  src: local("Cubic"),
  url("./assets/fonts/cubic.ttf");
  font-weight: bold;
}